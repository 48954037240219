import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import WebPageTitle from "../components/WebPageTitle";

function About() {
  return (
    <div>
      <WebPageTitle title="About us" /> {/*page title */}
      <Breadcrumb pageTitle="About us" />
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark"></div>
                <div className="rotate-right bg-dark"></div>
                <img
                  src="img/image-1.png"
                  className="img-fluid h-100"
                  alt="img"
                />
                {/* <div className="bg-white experiences">
                  <h1 className="display-3">20</h1>
                  <h6 className="fw-bold">Years Of Experiences</h6>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h1 className="display-5 mb-2">
                  Shaping the Future of Business with Furaha Technologies
                </h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  At Furaha Technologies, we believe in leveraging the power of
                  technology to unlock the true potential of businesses. With
                  our comprehensive suite of innovative solutions, we help
                  businesses across industries streamline their operations,
                  enhance productivity, and drive sustainable growth.
                  <br />
                  <br /> With a focus on cutting-edge technologies,
                  user-friendly interfaces, and industry best practices, our
                  solutions are designed to deliver exceptional results. We
                  pride ourselves on our commitment to excellence, ensuring that
                  every solution we provide is reliable, scalable, and
                  future-proof.
                </p>
                <div className="row">
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-city fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Cutting-Edge Technology</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-school fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Streamlined Operations</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-warehouse fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>User-Friendly Interfaces</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-hospital fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Future-Proof Solutions</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid services py-5">
        <div className="container text-center py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-eye fa-3x text-primary"></i>
                  </div>
                </div>
                <h4>Vision</h4>
                <i>Creating Solutions Today for a Thriving Tomorrow</i>
                <p className="fs-5">
                  This vision statement emphasizes the proactive nature of
                  Furaha Technologies in creating innovative solutions for
                  businesses, aligning with your tagline "creating solutions,"
                  while also highlighting the long-term benefits and impact of
                  your work.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay=".5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp",
              }}
            >
              <div className="bg-light rounded p-5 services-item">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="mb-4 rounded-circle services-inner-icon">
                    <i className="fa fa-rocket fa-3x text-primary"></i>
                  </div>
                </div>
                <h4 className="text-center">Mission</h4>
                <p className="text-center fs-5">
                  At Furaha Technologies, our mission is to passionately create
                  cutting-edge solutions today that empower businesses for a
                  thriving tomorrow. We specialize in crafting innovative
                  software solutions tailored to the unique needs of our
                  clients, fueling their growth, enhancing efficiency, and
                  driving success in dynamic markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid services py-5">
        <div className="container text-center py-5">
          <div className="row g-5">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Innovation
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>We thrive on innovation.</strong> Our commitment to
                    creativity and forward thinking drives us to explore new
                    solutions and embrace change as an opportunity for growth.
                    We encourage a culture of experimentation and continuously
                    seek innovative ways to address evolving challenges.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Excellence
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>We are dedicated to excellence.</strong> Our
                    commitment to quality, precision, and attention to detail
                    ensures that every solution we provide exceeds expectations.
                    We strive for excellence in everything we do, delivering
                    exceptional results to our clients.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Integrity
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>Integrity is at the heart of our business.</strong>{" "}
                    We uphold the highest ethical standards, ensuring honesty,
                    transparency, and trustworthiness in all our interactions
                    with clients, partners, and team members. We value integrity
                    as a cornerstone of our success.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Client-Centric
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>Our clients are at the center of our focus.</strong>{" "}
                    We actively listen to their needs, provide personalized
                    solutions, and go the extra mile to ensure their success.
                    Their satisfaction is our greatest achievement.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Collaboration
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <strong>We believe in the power of collaboration.</strong>{" "}
                    We work as a cohesive team and actively engage with clients
                    and partners to achieve shared goals. Together, we
                    accomplish more.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About;
