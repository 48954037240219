import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WebPageTitle from "../components/WebPageTitle";
import Breadcrumb from "../components/Breadcrumb";

function Products() {
  const navigate = useNavigate();
  return (
    <>
      <WebPageTitle title="Products" />
      <Breadcrumb pageTitle="Products"/>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Our Products
            </h5>
            <h1 className="display-5">Discover Our Innovative Product Suite</h1>
          </div>

          {/* Furaha SMS */}
          <div className="row g-5 mb-5">
            <div
              className="col-lg-6 col-md-12 order-2 order-lg-1 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-item overflow-hidden">
                <h1 className="display-5 mb-2">Furaha SMS</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  Furaha SMS is your go-to platform for affordable and efficient
                  SMS-based communication, offering rates as low as UGX 35 per
                  message. Designed with an elegant and user-friendly interface,
                  our platform provides not just a service, but an experience.
                  Businesses looking for seamless integration can take advantage
                  of our robust SMS API to send messages directly from their own
                  platforms. Proudly serving clients in over five countries
                  across Africa, we are committed to widening our reach and
                  impact. Furaha SMS stands as a reliable, versatile, and
                  cost-effective solution for all your messaging needs.
                </p>
                <a
                  href="https://furahasms.com"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 w-md-25 w-sm-50"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 order-1 order-lg-2 wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-img">
                <div className="rotate-left bg-dark"></div>
                <div className="rotate-right bg-dark"></div>
                <img src="img/sms.png" className="img-fluid h-100" alt="img" />
              </div>
            </div>
          </div>

          {/* Furaha Banking */}
          <div className="row g-5 pt-5 mb-5">
            <div
              className="col-lg-6 col-md-12 order-2 order-lg-2 wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-item overflow-hidden">
                <h1 className="display-5 mb-2">Furaha Banking</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  Furaha Banking is an all-encompassing web-based software
                  solution designed exclusively for Savings and Credit
                  Cooperative Societies (SACCOs). It simplifies SACCO operations
                  by efficiently managing loans, member data, deposits,
                  withdrawals, and more. With its intuitive user interface,
                  Furaha Banking boosts efficiency and enhances member services.
                  SACCOs benefit from streamlined loan processes, real-time loan
                  tracking, and simplified member management. Additionally, it
                  ensures smooth transaction handling, empowering members with
                  hassle-free banking. The software also provides valuable
                  insights through real-time financial reports, aiding informed
                  decision-making and compliance. Furaha Banking is the perfect
                  solution for SACCOs seeking to improve member services while
                  optimizing financial operations.
                </p>
                <a
                  href="https://furahabanking.com"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 w-md-25 w-sm-50"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 order-1 order-lg-1 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-img">
                <div className="rotate-left bg-dark"></div>
                <div className="rotate-right bg-dark"></div>
                <img
                  src="img/banking.png"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
          </div>

          {/* Furaha Loans */}
          <div className="row g-5 pt-5">
            <div
              className="col-lg-6 col-md-12 order-2 order-lg-1 wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-item overflow-hidden">
                <h1 className="display-5 mb-2">Furaha Loans</h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  Furaha Loans is a dedicated application transforming loan
                  management for financial institutions. This specialized tool
                  streamlines loan processing, from applications to approvals,
                  reducing manual effort and saving time. Furaha Loans includes
                  a robust eligibility assessment feature, enabling institutions
                  to swiftly and accurately determine borrower eligibility based
                  on predefined criteria. It enhances borrower communication
                  with automated SMS reminders, ensuring timely repayments.
                  Moreover, the application simplifies the management of
                  guarantors and loan securities, reducing risk and promoting
                  responsible lending practices.
                </p>
                <a
                  href="https://furahaloans.com"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 w-md-25 w-sm-50"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 order-1 order-lg-2 wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              <div className="about-img">
                <div className="rotate-left bg-dark"></div>
                <div className="rotate-right bg-dark"></div>
                <img
                  src="img/loans.png"
                  className="img-fluid h-100"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
