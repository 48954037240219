import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Cta from "../../components/Cta";
import WebPageTitle from "../../components/WebPageTitle";

function FurahaLoan() {
  return (
    <div>
      <WebPageTitle title="Furaha Loan App" /> {/*page title */}
      <Breadcrumb pageTitle="Furaha Loan App" />
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mb-4 pb-2">
                <h6 className="position-relative text-primary ps-4">
                  Furaha Technologies
                </h6>
                <h2 className="mt-2">
                  Your Gateway to Streamlined Business Operations and Growth.
                </h2>
              </div>
              <p className="mb-4">
                At Furaha Technologies, we believe in leveraging the power of
                technology to unlock the true potential of businesses. With our
                comprehensive suite of innovative solutions, we help businesses
                across industries streamline their operations, enhance
                productivity, and drive sustainable growth.
              </p>
              <p className="mb-4">
                With a focus on cutting-edge technologies, user-friendly
                interfaces, and industry best practices, our solutions are
                designed to deliver exceptional results. We pride ourselves on
                our commitment to excellence, ensuring that every solution we
                provide is reliable, scalable, and future-proof.
              </p>
              <div className="row g-3">
                <div className="col-sm-6">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Award
                    Winning
                  </h6>
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>
                    Professional Staff
                  </h6>
                </div>
                <div className="col-sm-6">
                  <h6 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>24/7
                    Support
                  </h6>
                  <h6 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Fair Prices
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <a className="btn btn-primary rounded-pill px-4 me-3" href="">
                  Read More
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="img/about.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div
            className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h6 className="position-relative d-inline text-primary ps-4">
              SMS Features
            </h6>
            <h2 className="mt-2">What you'll enjoy with our SMS</h2>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">SEO Optimization</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">Web Design</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">Social Media Marketing</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">Email Marketing</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">PPC Advertising</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="feature-item d-flex flex-column justify-content-center text-center rounded">
                <h5 className="mb-3">App Development</h5>
                <p>
                  Erat ipsum justo amet duo et elitr dolor, est duo duo eos
                  lorem sed diam stet diam sed stet lorem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta />
    </div>
  );
}

export default FurahaLoan;
