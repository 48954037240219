import React, { useState } from 'react';
import { Link, NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import "./styles.css";
import Home from './views/Home';
import Header from './components/Header';
import About from './views/About';
import Footer from './components/Footer';
import Services from './views/Services';
import Contact from './views/Contact';
import FurahaSms from './views/services/FurahaSms';
import FurahaLoan from './views/services/FurahaLoan';
import FurahaSacco from './views/services/FurahaSacco';
import WebAppDevelopment from './views/services/WebAppDevelopment';
import MomoIntegration from './views/services/MomoIntegration';
import MobileAppDevelopment from './views/services/MobileAppDevelopment';
import Products from './views/Products';

function App () {
  const navigate = useNavigate();
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);

  // Function to open/close the off-canvas menu
  const toggleOffCanvas = () => {
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };

  return (
    <div className="body-wrapper overflow-hidden">
      <Header toggleOffCanvas={toggleOffCanvas} />
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/products" element={<Products/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/furaha-sms" element={<FurahaSms />}/>
          <Route path="/furaha-loan" element={<FurahaLoan />}/>
          <Route path="/furaha-sacco" element={<FurahaSacco />}/>
          <Route path="/momo-integration" element={<MomoIntegration />}/>
          <Route path="/web-app-development" element={<WebAppDevelopment />}/>
          <Route path="/mobile-app-development" element={<MobileAppDevelopment />}/>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
