import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WebPageTitle from "../components/WebPageTitle";
import Breadcrumb from "../components/Breadcrumb";

function Services() {
  const navigate = useNavigate();
  return (
    <>
    <WebPageTitle title="Services" />
    <Breadcrumb pageTitle="Services"/>
    <div className="container-fluid services py-5">
      <div className="container text-center py-5">
        <div
          className="text-center mb-5 wow fadeInUp"
          data-wow-delay=".3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            Our Services
          </h5>
          <h1 className="display-5">Create Solutions with our Services</h1>
        </div>
        <div className="row g-5">
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="bg-light rounded p-5 services-item">
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="mb-4 rounded-circle services-inner-icon">
                  <i className="fa fa-search fa-3x text-primary"></i>
                </div>
              </div>
              <h4>SEO Optimization</h4>
              <p className="fs-5">
                Furaha Technologies is your partner in achieving high search
                engine rankings and driving organic traffic to your website. Our
                SEO optimization services are designed to increase your online
                visibility and help potential customers find you easily.
              </p>
              <Link
                to="/contact"
                className="btn btn-primary border-0 rounded-pill px-4 py-3"
              >
                Get quote
              </Link>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-delay=".5s"
            style={{
              visibility: "visible",
              animationDelay: "0.5s",
              animationName: "fadeInUp",
            }}
          >
            <div className="bg-light rounded p-5 services-item">
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="mb-4 rounded-circle services-inner-icon">
                  <i className="fa fa-laptop fa-3x text-primary"></i>
                </div>
              </div>
              <h4 className="text-center">Website Design & Development</h4>
              <p className="text-center fs-5">
                Our team of skilled designers and developers is committed to
                creating websites that not only look stunning but also function
                seamlessly. We believe that a well-designed website is a
                powerful tool to engage your audience and drive business growth
              </p>
              <Link
                to="/contact"
                className="btn btn-primary border-0 rounded-pill px-4 py-3"
              >
                Get quote
              </Link>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-delay=".7s"
            style={{
              visibility: "visible",
              animationDelay: "0.7s",
              animationName: "fadeInUp",
            }}
          >
            <div className="bg-light rounded p-5 services-item">
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="mb-4 rounded-circle services-inner-icon">
                  <i className="fa fa-mobile-alt fa-3x text-primary"></i>
                </div>
              </div>
              <h4 className="text-center">App Development</h4>
              <p className="text-center fs-5">
                Furaha Technologies specializes in crafting mobile applications
                that cater to your unique requirements and objectives. Our goal
                is to provide you with a robust, user-friendly, and visually
                appealing app that sets you apart from the competition.
              </p>
              <Link
                to="/contact"
                className="btn btn-primary border-0 rounded-pill px-4 py-3"
              >
                Get quote
              </Link>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-delay=".9s"
            style={{
              visibility: "visible",
              animationDelay: "0.9s",
              animationName: "fadeInUp",
            }}
          >
            <div className="bg-light rounded p-5 services-item">
              <div
                className="d-flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="mb-4 rounded-circle services-inner-icon">
                  <i className="fa fa-credit-card fa-3x text-primary"></i>
                </div>
              </div>
              <h4 className="text-center">Payment Integrations</h4>
              <p className="text-center fs-5">
                Efficient and secure payment processing is essential for any
                online business. Furaha Technologies offers seamless payment
                integration solutions, allowing you to accept payments through
                various channels, including mobile money and card payments.
              </p>
              <Link
                to="/contact"
                className="btn btn-primary border-0 rounded-pill px-4 py-3"
              >
                Get quote
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Services;
