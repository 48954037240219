function Cta() {
  const styles = {
    width: "50px",
    height: "50px",
  };

  return (
    <div
      className="container-xxl bg-white newsletter px-5 my-5 wow fadeInUp"
      data-wow-delay="0.1s"
    >
      <div
        className="container px-lg-5"
        style={{
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
        }}
      >
        <div className="row align-items-center" style={{ height: 250 + "px" }}>
          <div className="col-12 col-md-6">
            <h3 className="text-primary">
              Ready to Elevate Your Business with Furaha Technologies?
            </h3>
            <p>
              Experience the transformative power of our innovative solutions
              and propel your business to new heights of success. Embrace the
              future of technology with Furaha Technologies today!
            </p>
            <div className="mt-3">
              <button type="button" className="btn btn-primary">
                Get Started Now
              </button>
            </div>
          </div>
          <div className="col-md-6 text-center mb-n5 d-none d-md-block">
            <img
              className="img-fluid mt-5"
              style={{ height: 250 + "px" }}
              src="img/newsletter.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;
