import React from "react";
import Navbar from "./Navbar";
import Offcanvas from "./Offcanvas";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="container-fluid topbar-top bg-primary">
        <div className="container">
          <div className="d-flex justify-content-between topbar py-2">
            <div className="d-flex align-items-center flex-shrink-0 topbar-info">
              {/* <a href="#" className="me-4 text-white"><i className="fas fa-map-marker-alt me-2 text-light"></i>123 Street, CA, USA</a> */}
              <a href="#" className="me-4 text-white">
                <i className="fas fa-phone-alt me-2 text-light"></i>+256 762 525
                603 / +256 753 567 803
              </a>
              <a href="#" className="text-white">
                <i className="fas fa-envelope me-2 text-light"></i>
                info@furahatechnologies.com
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center topbar-icon">
              <a href="#" className="me-4">
                <i className="fab fa-facebook-f text-light"></i>
              </a>
              <a href="#" className="me-4">
                <i className="fab fa-twitter text-light"></i>
              </a>
              <a href="#" className="me-4">
                <i className="fab fa-instagram text-light"></i>
              </a>
              <a href="#" className="">
                <i className="fab fa-linkedin-in text-light"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-lg-0">
            <Link to="/" className="navbar-brand">
              <img src="img/logo.png" />
            </Link>
            <button
              className="navbar-toggler bg-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars text-light"></span>
            </button>
            <div className="collapse navbar-collapse me-n3" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <Link to="/" className="nav-item nav-link active">
                  Home
                </Link>
                <Link to="/products" className="nav-item nav-link">
                  Products
                </Link>
                <Link to="/services" className="nav-item nav-link">
                  Services
                </Link>
                <Link to="/about" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/clients" className="nav-item nav-link">
                  Our Clients
                </Link>
                <Link to="/contact" className="nav-item nav-link">
                  Contact
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
