import React, { useEffect, useState } from "react";
import WebPageTitle from "../components/WebPageTitle";
import { Link, NavLink } from "react-router-dom";

function Home() {
  useEffect(() => {
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      navText: [
        "<i className='bi bi-arrow-left'></i>",
        "<i className='bi bi-arrow-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 3,
        },
      },
    });
  }, []);

  return (
    <div>
    <WebPageTitle title="Home" /> {/*page title */}
      <div className="container-fluid carousel px-0 mb-5 pb-5 bg-light">
        <div className="container col-xxl-8 px-4 pt-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="img/hero.png"
                className="d-block mx-lg-auto img-fluid"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="col-lg-6">
              <div className="lc-block mb-3">
                <div editable="rich">
                  <h2 className="fw-bold display-5">
                    'Creating Solutions' isn't just our tagline -it's our
                    promise.
                  </h2>
                </div>
              </div>

              <div className="lc-block mb-3">
                <div editable="rich">
                  <p className="lead">
                    Empowering Businesses with Cutting-Edge and Innovative
                    Solutions for Enhanced Efficiency, Growth, and Success.
                  </p>
                </div>
              </div>

              <div className="lc-block d-grid gap-2 d-md-flex justify-content-md-start">
                <a
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5"
                  href="#"
                  role="button"
                >
                  Get started now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="about-img">
                <div className="rotate-left bg-dark"></div>
                <div className="rotate-right bg-dark"></div>
                <img
                  src="img/image-1.png"
                  className="img-fluid h-100"
                  alt="img"
                />
                {/* <div className="bg-white experiences">
                  <h1 className="display-3">20</h1>
                  <h6 className="fw-bold">Years Of Experiences</h6>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="about-item overflow-hidden">
                <h1 className="display-5 mb-2">
                  Shaping the Future of Business with Furaha Technologies
                </h1>
                <p className="fs-5" style={{ textAlign: "justify" }}>
                  Dive into Furaha Technologies: where innovation meets
                  practicality. Revolutionize your business with our
                  cutting-edge software solutions, tailor-made for today's
                  dynamic markets. Elevate performance, streamline operations,
                  and unlock potential. Be part of the future. Join us and
                  experience the pinnacle of efficiency and growth. Your success
                  journey starts here
                </p>
                <div className="row">
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-city fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Cutting-Edge Technology</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-school fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Streamlined Operations</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-warehouse fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>User-Friendly Interfaces</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="text-center">
                      <div
                        className="p-4 bg-primary rounded d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="fas fa-hospital fa-4x text-light"></i>
                      </div>
                      <div className="my-2">
                        <h5>Future-Proof Solutions</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="/about"
                  className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 w-sm-100 w-md-auto"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Our Solutions
            </h5>
            <h1 className="display-5">
              Products creating solutions for businesses
            </h1>
          </div>
          <div className="row g-5">
            <div
              className="col-xxl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="project-item">
                <div className="project-left bg-dark"></div>
                <div className="project-right bg-dark"></div>
                <img src="img/sms.png" className="img-fluid h-100" alt="img" />
                <a
                  href="https://furahasms.com"
                  className="fs-4 fw-bold text-center"
                >
                  Furaha SMS
                </a>
              </div>
            </div>
            <div
              className="col-xxl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="project-item">
                <div className="project-left bg-dark"></div>
                <div className="project-right bg-dark"></div>
                <img
                  src="img/banking.png"
                  className="img-fluid h-100"
                  alt="img"
                />
                <a
                  href="https://furahabanking.com"
                  className="fs-4 fw-bold text-center"
                >
                  Furaha Banking
                </a>
              </div>
            </div>
            <div
              className="col-xxl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay=".7s"
            >
              <div className="project-item">
                <div className="project-left bg-dark"></div>
                <div className="project-right bg-dark"></div>
                <img
                  src="img/loans.png"
                  className="img-fluid h-100"
                  alt="img"
                />
                <a
                  href="https://furahasms.com"
                  className="fs-4 fw-bold text-center"
                >
                  Furaha Loans
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid testimonial py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              Testimonial
            </h5>
            <h1 className="display-5 w-50 mx-auto">
              What Clients Say About Our Services
            </h1>
          </div>
          <div
            className="testimonial-carousel wow fadeInUp"
            data-wow-delay=".5s"
          >
            <div className="row g-5">
              <div
                className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="bg-light rounded p-1 services-item">
                  <div class="card-body">
                    <h4 class="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>

                    <div class="template-demo">
                      <p>
                        Furaha SACCO has simplified our financial operations.
                        It's a comprehensive solution that has streamlined our
                        processes, making us more competitive in the market.
                      </p>
                    </div>

                    <hr />

                    <div class="row">
                      <div class="col-sm-2">
                        <img
                          class="profile-pic"
                          src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                        />
                      </div>

                      <div class="col-sm-10">
                        <div class="profile">
                          <h4 class="cust-name">Namusisi Jane</h4>
                          <p class="cust-profession">SACCO Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="bg-light rounded p-1 services-item">
                  <div class="card-body">
                    <h4 class="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>

                    <div class="template-demo">
                      <p>
                        Furaha Technologies transformed our business with their
                        innovative solutions. Our efficiency has soared, and
                        we've experienced remarkable growth. Their team's
                        dedication to excellence is truly commendable.
                      </p>
                    </div>

                    <hr />

                    <div class="row">
                      <div class="col-sm-2">
                        <img
                          class="profile-pic"
                          src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                        />
                      </div>

                      <div class="col-sm-10">
                        <div class="profile">
                          <h4 class="cust-name">Okware James</h4>
                          <p class="cust-profession">Business Owner</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
                data-wow-delay=".3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="bg-light rounded p-1 services-item">
                  <div class="card-body">
                    <h4 class="card-title">
                      <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" />
                    </h4>

                    <div class="template-demo">
                      <p>
                        Furaha Loans has empowered our lending institution.
                        Their future-proof solutions have given us a competitive
                        edge. We're on the path to even greater success with
                        Furaha.
                      </p>
                    </div>

                    <hr />

                    <div class="row">
                      <div class="col-sm-2">
                        <img
                          class="profile-pic"
                          src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                        />
                      </div>

                      <div class="col-sm-10">
                        <div class="profile">
                          <h4 class="cust-name">Adriko Eugene</h4>
                          <p class="cust-profession">SACCO Member</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid testimonial py-5">
        <div className="container py-5">
          <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
              get started
            </h5>
            <h1 className="display-5 w-70 mx-auto">
            Stay Ahead, Stay Innovative. Tap into Furaha Today!
            </h1>
          </div>
          <div
            className="testimonial-carousel wow fadeInUp"
            data-wow-delay=".5s"
          >
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp">
                <div
                  className=""
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="card-body text-center">
                    <p className="fs-5">
                      Elevate your enterprise, streamline operations, and lead
                      your market. Don't wait. Dive into unparalleled excellence
                      with us today
                    </p>
                    <div className="d-sm-flex align-items-center justify-content-center gap-3 mb-4">
                      <Link
                        className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 btn-hover-shadow"
                        type="button"
                        to="/contact"
                      >
                        Get in touch
                      </Link>
                      <Link
                        to="/products"
                        className="btn btn-secondary border-0 rounded-pill px-4 py-3 mt-5"
                        type="button"
                      >
                        View products
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="pt-8 pt-md-5 pb-5 pb-lg-10 pb-xl-12">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div
                className="card c2a-box aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="1600"
                data-aos-duration="1000"
              >
                <div className="card-body text-center p-4 pt-8">
                  <h3 className="fs-7 fw-semibold pt-6">
                    Stay Ahead, Stay Innovative. Tap into Furaha Today!
                  </h3>
                  <p className="mb-8 pb-2 text-dark">
                    Elevate your enterprise, streamline operations, and lead
                    your market. Don't wait. Dive into unparalleled excellence
                    with us today
                  </p>
                  <div className="d-sm-flex align-items-center justify-content-center gap-3 mb-4">
                    <a
                      className="btn btn-primary border-0 rounded-pill px-4 py-3 mt-5 btn-hover-shadow"
                      type="button"
                      href="/contact"
                    >
                      Get in touch
                    </a>
                    <a
                      href=""
                      className="btn btn-secondary border-0 rounded-pill px-4 py-3 mt-5"
                      type="button"
                    >
                      Submit Ticket
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Home;
