import { useEffect } from "react";

const WebPageTitle = ({ title }) => {
    useEffect(() => {
      document.title = title;
    }, [title]);
    
    return null;
}

export default WebPageTitle;