import { Link } from "react-router-dom";

function Footer() {
  const currentDate = new Date().getFullYear();

  return (
    // <footer className="footer-part pt-8 pb-5 bg-primary">
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-lg-4">
    //         <div className="text-center">
    //           <a href="index-new.html">
    //             <img
    //               src="dist/images/logos/logo-white.png"
    //               alt=""
    //               className="img-fluid pb-3"
    //               style={{ width: "200px", height: "100px" }}
    //             />
    //           </a>
    //           <p className="mb-0 text-light">
    //             All rights reserved &copy; Furaha Technologies{" "}
    //             {new Date().getFullYear()}.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <>
      <div
        className="container-fluid footer py-5 wow fadeIn"
        data-wow-delay=".3s"
      >
        <div className="container py-5">
          <div className="row g-4 footer-inner">
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">
                  About Furaha Technologies.
                </h4>
                <p>
                  With a focus on cutting-edge technologies, user-friendly
                  interfaces, and industry best practices, our solutions are
                  designed to deliver exceptional results.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Usefull Link</h4>
                <div className="d-flex flex-column align-items-start">
                  <Link className="btn btn-link ps-0" to="/about">
                    <i className="fa fa-check me-2"></i>About Us
                  </Link>
                  <Link className="btn btn-link ps-0" to="/contact">
                    <i className="fa fa-check me-2"></i>Contact Us
                  </Link>
                  <Link className="btn btn-link ps-0" to="/services">
                    <i className="fa fa-check me-2"></i>Our Services
                  </Link>
                  <Link className="btn btn-link ps-0" to="/blog">
                    <i className="fa fa-check me-2"></i>Our Blog
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Our Products</h4>
                <div className="d-flex flex-column align-items-start">
                  <a className="btn btn-link ps-0" href="https://furahasms.com">
                    <i className="fa fa-check me-2"></i>Furaha SMS
                  </a>
                  <a className="btn btn-link ps-0" href="">
                    <i className="fa fa-check me-2"></i>Furaha Loan
                  </a>
                  <a
                    className="btn btn-link ps-0"
                    href="https://furahabanking.com"
                  >
                    <i className="fa fa-check me-2"></i>Furaha Banking
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-item">
                <h4 className="text-white fw-bold mb-4">Contact Us</h4>
                {/* <a
                  href=""
                  className="btn btn-link w-100 text-start ps-0 pb-3 border-bottom rounded-0"
                >
                  <i className="fa fa-map-marker-alt me-3"></i>123 Street, CA, USA
                </a> */}
                <a
                  href=""
                  className="btn btn-link w-100 text-start ps-0 py-3 border-bottom rounded-0"
                >
                  <i className="fa fa-phone-alt me-3"></i>+256762525603/+256753567803
                </a>
                <a
                  href=""
                  className="btn btn-link w-100 text-start ps-0 py-3 border-bottom rounded-0"
                >
                  <i className="fa fa-envelope me-3"></i>
                  info@furahatechnologies.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright bg-dark py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center text-md-start mb-3 mb-md-0">
              {/* <a href="#" className="text-primary mb-0 display-6">
                Pest<span className="text-white">Kit</span>
                <i className="fa fa-spider text-primary ms-2"></i>
              </a> */}
              <Link to="/" className="mb-0 display-6">
                <img src="img/logo-white.png" />
              </Link>
            </div>
            <div className="col-md-4 copyright-btn text-center text-md-start mb-3 mb-md-0 flex-shrink-0">
              <a
                className="btn btn-primary rounded-circle me-3 copyright-icon"
                href=""
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-primary rounded-circle me-3 copyright-icon"
                href=""
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-primary rounded-circle me-3 copyright-icon"
                href=""
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                className="btn btn-primary rounded-circle me-3 copyright-icon"
                href=""
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <div className="col-md-4 my-auto text-center text-md-end text-white">
              <p className="mb-0">
                <a className="" href="#">
                  Furaha Technologies{" "}
                </a>{" "}
                &copy; {new Date().getFullYear()}. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>

      <a
        href="#"
        className="btn btn-primary rounded-circle border-3 back-to-top"
      >
        <i className="fa fa-arrow-up"></i>
      </a>
    </>
  );
}

export default Footer;
