function ContactDetails () {
    return (
        <div className="container-xxl">
            <div className="container px-lg-5">
                <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                    <h2 className="mt-2">For any inquiries or support</h2>
                    <p>Email: info@furahatechnologies.com</p>
                            <p>Phone: +1 123-456-7890</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                        <div className="service-item d-flex flex-column justify-content-right text-center rounded">
                            <div className="service-icon flex-shrink-0">
                                <i className="fa fa-envelope fa-2x"></i>
                            </div>
                            
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactDetails;